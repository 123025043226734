import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "46",
  height: "46",
  viewBox: "0 0 46 46",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g id=\"Group 39\"><path id=\"Vector (Stroke)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M23 3.58398C14.5979 3.58398 7.79346 10.3801 7.79346 18.784V25.4173H4.79346V18.784C4.79346 8.72124 12.9431 0.583984 23 0.583984C33.0559 0.583984 41.2065 8.72026 41.2065 18.7673V25.4007H38.2065V18.7673C38.2065 10.381 31.403 3.58398 23 3.58398Z\" fill=\"#212529\"></path><path id=\"Vector (Stroke)_2\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.77686 31.168C7.77686 35.6542 11.426 39.3013 15.9236 39.3013H19.7623V42.3013H15.9236C9.77307 42.3013 4.77686 37.315 4.77686 31.168H7.77686Z\" fill=\"#212529\"></path><path id=\"Vector\" d=\"M11.532 16.1016H0.5V34.7182H11.532V16.1016Z\" fill=\"#F06F02\"></path><path id=\"Vector_2\" d=\"M45.5 16.1016H34.468V34.7182H45.5V16.1016Z\" fill=\"#F06F02\"></path><path id=\"Vector_3\" d=\"M19.295 45.4177C21.9036 45.4177 24.0182 43.306 24.0182 40.701C24.0182 38.0961 21.9036 35.9844 19.295 35.9844C16.6864 35.9844 14.5718 38.0961 14.5718 40.701C14.5718 43.306 16.6864 45.4177 19.295 45.4177Z\" fill=\"#212529\"></path></g>", 1)
  ])))
}
export default { render: render }